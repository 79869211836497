<template>
  <div v-if="displayMe" class="accordion-container" :class="[idx, accordionClass]">
    <div class="title-container" @click="handleClick()">
      <h3 class="title">{{ title }}</h3>
      <img src="~/assets/imgs/gestion/faq-arrow.svg" :alt="title" width="9" height="16" loading="lazy" />
    </div>
    <div class="content" v-html="content" />
  </div>
</template>

<script>
export default {
  props: {
    idx: { type: String, required: true },
    title: { type: String, required: true },
    content: { type: String, required: true },
    displayMe: { type: Boolean, default: true },
  },
  emits: ['click'],
  computed: {
    accordionClass() {
      return `accordion-${this.idx}`
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="less" scoped>
.accordion-container {
  .title,
  .content {
    text-align: left;
    font-weight: 400;
  }
  &.open {
    .title-container {
      border: none;

      img {
        transform: rotate(90deg);
      }
    }

    .content {
      display: block;
      border-bottom: 1px solid #eeeeee;
    }
  }
  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;

    img {
      transition: all 0.3s ease;
      overflow: hidden;
    }
  }
  .title {
    font-size: 14px;
    color: #272727;
    margin-top: 0;
    margin-bottom: 0;
  }
  .content {
    padding-bottom: 25px;
    font-size: var(--ds-font-sm);
    color: #6b6651;
    display: none;
  }

  &:first-child .title-container {
    padding-top: 0px;
  }

  @media @bp-desktop {
    padding: 32px 30px;
    transition: 250ms all ease;

    .title-container {
      cursor: auto;
      border-bottom: unset;
      padding-bottom: 0;
      padding-top: 0;
      img {
        display: none;
      }
      .title {
        font-size: 24px;
      }
    }

    .content {
      display: block;
      padding-bottom: 0;
      margin-top: 22px;
      font-size: 18px;
      color: rgba(107, 102, 81, 1);
    }

    &.open {
      .content {
        border: none;
      }
    }
  }
}
</style>
