<template>
  <div id="faq-scroll-container" ref="scroll-container">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    currentQuestion: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      scrollNode: null,
      elements: [],
    };
  },
  emits: ["setCurrentQuestion"],
  methods: {
    isScrolledIntoView(elem) {
      return this.scrollNode.scrollTop <= elem.offsetTop;
    },

    getCurrentVisibleQuestionClass() {
      let latestVisibleClass = 0;

      for (var index in this.elements) {
        if (this.elements[index]) {
          if (this.isScrolledIntoView(this.elements[index])) {
            latestVisibleClass = index;
            break;
          }
        }
      }

      return latestVisibleClass.toString();
    },

    manageScroll() {
      const currentVisibleQuestionClass = this.getCurrentVisibleQuestionClass();

      if (currentVisibleQuestionClass !== this.currentQuestion) {
        this.$emit('setCurrentQuestion', currentVisibleQuestionClass)
      }
    },
  },
  mounted() {
    const nodelist = document.getElementsByClassName("accordion-container")
    for (const i in nodelist) this.elements.push(nodelist[i])

    this.scrollNode = this.$refs['scroll-container']
    this.scrollNode.addEventListener('scroll', this.manageScroll)
  },
  beforeDestroy() {
    this.scrollNode.removeEventListener('scroll', this.manageScroll)
  }
}
</script>

<style lang="less" scoped></style>
