<template>
  <Section class="faq flex-col flex-center">
    <template v-if="$slots.title">
      <slot name="title" />
    </template>
    <h2
      v-else
      class="title"
    >
      Nous avons réponse à
      <strong>toutes vos questions</strong>
    </h2>
    <div class="container faq-container">
      <div class="titles hide-on-mobile">
        <div
          class="faq-title"
          :class="{ current: $index.toString() === currentQuestion }"
          v-for="(title, $index) in titles"
          :key="$index"
          @click="select($index)"
        >
          <h3 v-if="displayTitle($index)">{{ title }}</h3>
        </div>
      </div>
      <FaqScroll
        class="faq-accordions"
        ref="faqScrollContainer"
        @setCurrentQuestion="handleSetCurrentQuestion"
      >
        <Accordion
          v-for="(q, $index) in faq"
          :key="$index"
          :title="q.title"
          :content="q.content"
          @click="handleAccordionClick($index.toString())"
          :idx="$index.toString()"
          :class="{ current: $index.toString() === currentQuestion }"
          :displayMe="displayContent(q.partnerRestricted)"
        />
      </FaqScroll>
    </div>
  </Section>
</template>

<script>
import { useAuthStore } from '../../stores/auth'

export default {
  data() {
    return {
      currentQuestion: '0',
    }
  },
  computed: {
    titles() {
      return this.faq.map((f) => f.title)
    },
    businessPartner() {
      const partnerCookie = useCookie('partner_name')
      const authStore = useAuthStore()
      return authStore.user?.partner_name || partnerCookie.value
    },
  },
  props: {
    faq: {
      type: Array,
      required: true,
    },
  },
  methods: {
    displayTitle(index) {
      const isRestricted = this.faq[index].partnerRestricted
      if (this.businessPartner && isRestricted) return false
      return true
    },
    displayContent(isRestricted) {
      if (isRestricted && this.businessPartner) return false
      return true
    },
    select(index) {
      let elmt = this.$el.getElementsByClassName('accordion-container ' + index)
      this.$refs.faqScrollContainer.$el.scrollTo({ top: elmt[0].offsetTop, behavior: 'smooth' })
    },
    handleAccordionClick(index) {
      const nodelist = document.querySelectorAll('.accordion-container.open')
      let cancelOpen = false

      for (const i in nodelist) {
        if (nodelist[i].classList) {
          nodelist[i].classList.remove('open')

          if (nodelist[i].classList.contains(index)) cancelOpen = true
        }
      }

      if (!cancelOpen) document.querySelector('.accordion-' + index).classList.add('open')
    },
    handleSetCurrentQuestion(question) {
      this.currentQuestion = question
    },
  },
  jsonld() {
    let faqEntities = []
    this.faq.forEach((f) => {
      faqEntities.push({
        '@type': 'Question',
        name: f.title,
        acceptedAnswer: {
          '@type': 'Answer',
          text: f.content.replace(/<\/?[^>]+(>|$)/g, ''),
        },
      })
    })
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqEntities,
    }
  },
}
</script>

<style lang="less" scoped>
:deep(.section-container) {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2.title {
    font-size: 24px;
  }

  @media @bp-desktop {
    h2.title {
      font-size: 32px;
    }
  }

  .title {
    width: 100%;
    font-size: var(--ds-font-md);
    font-weight: var(--ds-weight-semi-bold);
    color: #272727;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 30px;
    transition-delay: 100ms;

    @media @bp-desktop {
      font-size: 32px;
      margin-bottom: 65px;
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media @bp-md-down {
    padding: 0 30px;
  }

  .titles {
    max-width: 400px;
    width: 40%;

    @media @bp-desktop {
      max-height: 70vh;
      overflow-y: scroll;
    }

    @media @bp-desktop-xl {
      max-height: 600px;
    }

    .faq-title {
      position: relative;
      font-size: var(--ds-font-md);
      text-align: left;
      cursor: pointer;
      transition: all 0.3s ease;

      h3 {
        font-weight: var(--ds-weight-semi-bold);
      }

      &:before {
        position: absolute;
        left: -16px;
        content: '';
        width: 0;
        height: 0;
        transition: all 0.3s ease;
      }

      &:not(:last-child) {
        margin-bottom: 28px;
      }

      &.current {
        color: var(--ds-color-primary-100);

        &:before {
          width: 8px;
          height: 100%;
          background-color: var(--ds-color-primary-100);
        }
      }
    }
  }

  .faq-accordions {
    :deep(a) {
      color: var(--ds-color-primary-100);
      text-decoration: none;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
    @media @bp-desktop {
      position: relative;
      flex: 1;
      max-height: 70vh;
      overflow-y: scroll;
      padding-right: 20px;
      margin-left: 28px;

      .current {
        background-color: var(--ds-color-primary-25);
        border-radius: 10px;

        :deep(.title) {
          color: var(--ds-color-primary-100);
        }
      }

      &::-webkit-scrollbar {
        width: 14px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--ds-color-gray-50);
        border-radius: 17px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-track {
        background-color: #eeeeee;
        border-radius: 17px;
      }

      :deep(.accordion-container:not(:first-child)) {
        margin-top: 8px;
      }
    }

    @media @bp-desktop-xl {
      max-height: 600px;
    }

    :deep(.accordion-container) .title-container .title {
      font-weight: var(--ds-weight-semi-bold);
      margin: 0;
      padding: 0;
      text-align: left;
    }
  }
}
@media @bp-desktop {
  :deep(.section-container) {
    max-width: @bp-desktop-max-width;
  }
}
</style>
